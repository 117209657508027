module lernbuch {

	/**
	 * SearchResult Class.
	 */
	export class SearchResult {

		public excerpts:Array<string>;
		public book:BookModel;
		public chapter:ChapterModel;
		public element:ElementModel;
		
		/**
		 * Instantiates a SearchResult
		 */
		constructor( element:ElementModel ) {
			this.element = element;
			this.excerpts = [];
		}
		
		public makeExcerpt( term:string, text:string ) {
			// limit defines how many chracters are included before and after the found term
			var limit = 35;
						
			var indices = [];
			// get the indices within the text (positions of the matches within the text)
			text.replace( new RegExp( term, 'g' ), function( match, index ) {
				indices.push( index );
				return match;
			});
			
			// iterate over the indices
			for (var i = 0; i < indices.length; i++) {
				// define start and stop position
				var start = Math.max( 0, indices[i] - limit );
				var stop = Math.min( text.length, indices[i] + term.length + limit );
				// make the excerpt
				var excerpt = text.substring( start, stop );
				// wrap the term with the search-result template
				excerpt = excerpt.replace( new RegExp( term, 'g' ), function( match, index ) {
					return ln.Template.render( 'lb.search-result', { term:term } );
				});
				
				this.excerpts.push(excerpt);
			}
		}
		
		public toJSON() {
			return { excerpts: this.excerpts, element:this.element.json(), book:this.book.json(), chapter:this.chapter.json() }	
		}
	}
}