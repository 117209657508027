module lernbuch {

	export interface TitleModel extends ElementModel {
		get( key:'title' ):string;
		get( key:'level' ):number;
		get<T>( key:string, fallback?:T ):T;
	}
	
	/**
	 * This class renders a title element in a ChapterRenderer
	 */
	export class Title extends Element {

		public model:TitleModel;
		
		constructor( model:TitleModel ) {
			super( model );
		}

		/**
		 * Renders the element into its container.
		 */
		public render() {
			this.renderModel( 'lb.title-element' );
		}
	}

	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Title', Title );
}