module lernbuch {

	/**
	 * A common view class that has a container to render content/template into.
	 */
	export class View {

		public container:ln.Node;

		/**
		 * Instantiates a view with a container.
		 */
		constructor( container:ln.Node = ln.Node.fromTag( 'div' ) ) {
			this.setContainer( container );
		}


		/**
		 * Renders the template with the given key into the container.
		 */
		public renderTemplate( key:string, data:Object = {} ):void {
			this.container.setHTML( ln.Template.render( key, data ) );
		}

		/**
		 * injects the classname as a css class on the container
		 */
		private injectClassName() {

			// fetch the classname out of the constructor name
			var funcNameRegex = /function (.{1,})\(/;
			var match = (funcNameRegex).exec( this.constructor.toString() );

			if( match && match.length > 1 ) {
				var className = match[1];

				// from camelcase to dash seperated for css
				var newClassName = className.replace(/([A-Z])/g, function( cont, p1, index ) {
					return ( index == 0 ) ? cont.toLowerCase() : "-" + cont.toLowerCase();
				});
				this.container.removeClass( className );
				this.container.addClass( newClassName );

			}
		}

		public setContainer( con:ln.Node ) {

			// proper destroy of old container
			if( this.container ) delete this.container;

			this.container = con;
			this.injectClassName();
		}
	}
}