///<reference path="Model.ts"/>

module lernbuch {
	
	export class BookModel extends Model {
		
		get( key:'title' ):string;
		get( key:'chapters' ):Array<ChapterModel>;
		get<T>( key:string, fallback?:T ):T;
		
		get<T>( key:string, fallback?:T ):T {
			return super.get( key, fallback );
		}
		
		/**
		 * Returns the chapter model for the book with the given slug
		 */
		public getChapter( slug:number ):ChapterModel {

			// find the chapter with the given id.
			var found = this.get( 'chapters' ).filter( function( chapter:ChapterModel ) {
				return chapter.get( 'slug' ) === slug;
			});
			
			return found[0];
		}
		
		/**
		 * Creates a BookModel from the given json object
		 */
		public static fromJSON( json:{ chapters:Array<Object> } ):BookModel {
			
			var temp = [], chapters = json.chapters, elements;
			
			// loop over chapters
			chapters.forEach( function( chapterObj:{ elements:Array<Object> } ) {
				
				elements = [];
				
				// loop over elements
				if( chapterObj.elements ) {
					
					chapterObj.elements.forEach( function( element ) {
						elements.push( new ElementModel( element ) );
					});
				}
				
				chapterObj.elements = elements;
				temp.push( new ChapterModel( chapterObj ) );
			});
			
			json.chapters = temp;
			return new BookModel( json );
		}
	}
}