///<reference path="../../view/View.ts"/>

module lernbuch {

	/**
	 * renders all elements of all chapters into a line to quick navigation through them
	 */
	export class OutlineNavigation extends View {
        
        private elements:Array<ln.Node> = [];
		private lernbuch:LernBuch;

		constructor( lernbuch:LernBuch, container ) {
			super( container );
			
			this.lernbuch = lernbuch;

			// catch if window is resized
			ln.Window.resize.add( this.onResize, this );
			
			// listen to scroll changes
			lernbuch.scrollMonitor.change.add( this.onScroll, this );
		}
		
		/**
		 * Callback function when the window is resized to scale the outline navigation.
		 */
		onResize( event:Event ) {
			this.update();
		}
		
		/**
		 * Callback function when the lernbuch is scrolled.
		 */
		onScroll( elements:Array<Element> ) {
			this.update();
		}
		
		/**
		 * Updates the outline navigation based on the visible element of the lernbuch
		 */
		update() {
			
			var visible = this.lernbuch.scrollMonitor.visibleElements;
			
			// check for proper visible elements
			if( visible && visible.length > 0 ) {
				
				// now get the ration of the vertical scrollbar to select visible element based on scrollbar position
				var scrollInfo = ln.Window.scrollInfo();
				var ratio = scrollInfo.top / scrollInfo.height;
				
				// clamped index based on scrollbar ration 
				var index = Math.min( Math.max( Math.round( ratio * ( visible.length - 1 ) ), 0 ), visible.length - 1 );
				this.selectElement( visible[ index ] );
			}
		}
		
		/**
		 * Selects only the outline element based on the given element
		 */
		selectElement( element:Element ) {
			// search for the elment with the given model
			var found = ln.Array.filter( this.elements, function( current ) {
				return current.getAttribute( 'name' ) == element.model.slug();
			});
			
			// remove active class on all elements
			this.container.all( '.outline-navigation__element' ).removeClass( 'outline-navigation__element--active' );
			
			// add active class to element
			if( found && found.length == 1 ) found[0].addClass( 'outline-navigation__element--active' );
		}
		
		
		/**
		 * Renders all chapters and elements
		 */
		renderBook( book:BookModel ):void {
			
			// loop over chapters and elements
			book.get( 'chapters' ).forEach( ( chapter ) => {
				
				var chapterNode = ln.Node.fromHTML( ln.Template.render( 'lb.outline-chapter', chapter.json() ) );
				
				chapter.get( 'elements' ).forEach( ( element ) => {
					
					var elementNode = ln.Node.fromHTML( ln.Template.render( 'lb.outline-element', element.json() ) );
					elementNode.setAttribute( 'name', element.slug() );
					this.elements.push( elementNode );
					
					chapterNode.append( elementNode );
				});
				
				this.container.append( chapterNode );
			});
		}
	}
}