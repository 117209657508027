module lernbuch {
	
	/**
	 * Interface for all listener function that listen on the top signal
	 */
	export interface ScrollChangeListener {
		( visible:Array<Element>, ...args:any[] );
	}
	
	/**
	 * A class that monitors the visible elements of a lernbuch
	 */
	export class ScrollMonitor {
		
		private lernbuch:LernBuch;
		
		// Offest-Top and Offset-Bottom (in case of fixed header / footer)
		public offsetTop:number = 40;
		public offsetBottom:number = 0;
		
		public visibleElements:Array<Element> = [];
		
		// event when the top visible element has changed
		public change:ln.Signal<ScrollChangeListener> = new ln.Signal<ScrollChangeListener>();
		
		constructor( lernbuch:LernBuch ) {
			this.lernbuch = lernbuch;
			
			// register on window events
			ln.Window.resize.add( this.update, this );
			ln.Window.scroll.add( this.update, this );
			
        }
		
		/**
		 * Iterates over all the rendered Elements and updates the visibleElements array
		 */
		public update() {
			
			var scrollInfo = ln.Window.scrollInfo();
			var viewport = ln.Window.viewport();
			
			var viewportTop:number = scrollInfo.top + this.offsetTop;
            var viewportHeight:number = viewport.height - (this.offsetTop + this.offsetBottom);
			var viewportBottom:number = viewportTop + viewportHeight;
			
			var currentElements:Array<Element> = [];
			var allElements = this.lernbuch.view.currentChapter.getElements();
			
			// loop over the rendered elements
			allElements.forEach( ( element:Element )=> {
				
				var bounds = element.container.bounds();
				
				// element larger than viewPort
				if (bounds.top < viewportTop && bounds.bottom > viewportBottom) {
					currentElements.push( element );
					return;
				}
				
				// element fully inside viewport
				if( bounds.top >= viewportTop && bounds.bottom <= viewportBottom) {
					currentElements.push( element );
					return;
				}
									
				// partial visible, calc overlappping height,
				// if more than 50% of viewport is covered -> add
				var visibleTop = Math.max( bounds.top, viewportTop );
				var visibleBot = Math.min( bounds.bottom, viewportBottom );
				if( ( visibleBot - visibleTop ) / viewportHeight >= 0.5 ) {
					currentElements.push( element );
					return;
				}
            });
			 
			// compare 
			this.compareElements( currentElements );
        }
		
		/**
		 * Compares the given current elements and check with the visibleElements which are still visible or not.
		 */
		private compareElements( currentElements:Array<Element> ) {
			
			var hasChange = false;
			
			// check if current element is new visible
			currentElements.forEach( ( element:Element, index )=> {
				if( this.visibleElements.indexOf(element) == -1 ) {
					element.isVisible.dispatch();
					hasChange = true;
				}
			});
			
			// check if any of the old elements are not visible anymore.
			this.visibleElements.forEach( ( element, index )=> {
				if( currentElements.indexOf(element) == -1 ) {
					element.isHidden.dispatch();
					hasChange = true;
				}
			});
			
			// check if top element has changed
			hasChange = hasChange || currentElements[0] != this.visibleElements[0];
			
			// update the visible element
			this.visibleElements = currentElements;
			 
			if( hasChange ) this.change.dispatch( currentElements );
		}
	}
}