module lernbuch {
	
	export interface ImageModel extends ElementModel {
		get( key:'src' ):string;
		get( key:'caption' ):string;
		get<T>( key:string, fallback?:T ):T;
	}
	
	/**
	 * An element that renders an image
	 */
	export class Image extends Element {
		
		public model:ImageModel;
		
        constructor( model:ImageModel ) {
            super( model );
        }
        
		/**
		 * Image specific rendering
		 */
		public render() {
			this.renderModel( 'lb.image-element' );
        }
       
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Image', Image );
}