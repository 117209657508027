///<reference path="View.ts"/>
///<reference path="../navigation/chapter/ChapterNavigation.ts"/>
///<reference path="../navigation/chapter/ChapterNavigationItem.ts"/>

module lernbuch {

	
	/**
	 * Interface for all listener function that listen on the top signal
	 */
	export interface ChapterChangeListener {
		( chapter:Chapter, ...args:any[] );
	}
	
	/**
	 * The main LernBuch class that renders chapters and elements.
	 */
	export class LernBuchView extends View {

		public chapterChange:ln.Signal<ChapterChangeListener> = new ln.Signal<ChapterChangeListener>();
		public currentChapter:Chapter;
		

		/**
		 * Creates a new lernbuch instance
		 */
		constructor( container:ln.Node) {

			super( container );
			this.renderTemplate( 'lb.lernbuch' );
			
		}

		/**
		 * Shows the chapter with the given id.
		 */
		public renderChapter( chapterModel:ChapterModel ):void {
			
			var chapter = ElementRenderer.elementRegister.instantiate<Chapter>( chapterModel.get( 'modelName' ), chapterModel );
			chapter.setContainer( this.chapterContainer() );
			chapter.render();
			
			this.currentChapter = chapter;
			this.chapterChange.dispatch( this.currentChapter );
		}

		/**
		 * Returns the container where to render a chapter
		 */
		private chapterContainer():ln.Node {
			return this.container.one( '.chapter' );
		}
	}
}