///<reference path="../../view/View.ts"/>

module lernbuch {
	
	
	/**
	 * Renders a link to the previous chapter at the top of the chapter and a link to the next chapter at to bottom of the chapter
	 */
	export class PagingNavigation extends View {

		private lernbuch:LernBuch;

		constructor( lernbuch:LernBuch, container:ln.Node ) {
			
			super(container);
			
			this.lernbuch = lernbuch;
			
			// listen to scroll changes - event dont get fired by scroll monitior !!?
			//this.lernbuch.scrollMonitor.change.add( this.onScroll, this );

			// register on window events
			ln.Window.resize.add( this.updateVisibility, this );
			ln.Window.scroll.add( this.updateVisibility, this );


			// listen to chapter changes
			this.lernbuch.view.chapterChange.add( this.updateLink, this );

		}

		/**
		 * Renders the ui elements
		 */
		public render() {
			this.renderTemplate( 'lb.paging-navigation' );
		}

		
		/**
		 * Callback function when the lernbuch is scrolled.
		 */
		private onScroll( elements:Array<Element> ) {
			this.updateVisibility();
		}		
		
		
		private updateLink() {
			
			// get all chapter models
			var chapters = this.lernbuch.book.get( 'chapters' );			
			// get the model of the current chapter
			var currentChapter = this.lernbuch.view.currentChapter.model;
			
			// get the next chapter if existing
			var nextChapter = this.nextChapter( chapters, currentChapter );

			if(nextChapter != undefined) {
				// set the title of the next chapter
				this.container.one( '.paging-navigation__forward > span' ).setHTML( nextChapter.get( 'title' ) );
				// add new signal
				// do i have to remove the old signal before adding a new?
				this.container.one( '.paging-navigation__forward' ).click.add( this.onClick, this, nextChapter );
			}else {
				this.container.addClass( 'hidden' );
			}
			
		}



		private updateVisibility() {
			var scrollInfo = ln.Window.scrollInfo();

			// hide or show the buttons
			if( scrollInfo.height <= scrollInfo.top ) {
				this.container.one( '.paging-navigation__forward' ).addClass( 'visible' );
			}else {
				this.container.one( '.paging-navigation__forward' ).removeClass( 'visible' );
			}
		
		}
		
		
		/**
		 * Callback when one of the navigation items was clicked.
		 */
		private onClick( container:ln.Node, event:Event, chapter:ChapterModel ) {
			this.lernbuch.navigation.select.dispatch( chapter );
		}
		
		/**
		 * Return the next chapter if existant
		 */
		private nextChapter( chapters:ChapterModel [], currentChapter:ChapterModel ) {
			for (var i = 0; i < chapters.length; i++) {
				var chapter = chapters[i];
				if( chapter == currentChapter) {
					break;
				}
			}
			
			return chapters[i+1];
		}
		
	}
	
}