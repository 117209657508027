///<reference path="Element.ts"/>
///<reference path="ElementRenderer.ts"/>

module lernbuch {

	/**
	 * This class renders a chapter element
	 */
	export class Chapter extends Element {

		public model:ChapterModel;
		private renderer:ElementRenderer;
		
		constructor( model:ChapterModel ) {
			super( model );

			this.renderer = new ElementRenderer();
		}

		/**
		 * Renders the chapter into its container.
		 */
		public render() {
			// render template with model
			this.renderModel( 'lb.chapter-element' );

			// set container
			this.renderer.setContainer( this.elementContainer() );
			// render elements
			this.renderer.render( this.model.get( 'elements' ) );
			
			// add the click listener on the arrow down
			this.container.one( '.cover__down-arrow' ).click.add( () => {
				this.scrollTo( this.model.get( 'elements' )[0].slug() );
			});
			
			this.updateCover();
			
			// listen on window resize
			ln.Window.resize.add( this.updateCover, this );
		}

		private elementContainer():ln.Node {
			return this.container.one( '.elements' );
		}
		
		/**
		 * Updates the cover height on windows height
		 */
		private updateCover() {
			// check if there is a cover to update
			var cover = this.container.one( '.cover' );
			if( cover != undefined ) {
				cover.native.style.height = window.innerHeight + 'px';
			}
		}
		
		/**
		 * Returns all the rendered elements
		 */
		public getElements():Element[] {
			return this.renderer.elements;
		}
		
		/**
		 * Returns the element with the given element configuration
		 */
		public getElement( model:ElementModel ):Element {
			var found = this.renderer.elements.filter( function( element ) {
				return element.model === model;
			});
			
			return found[0];
		}
		
		/**
		 * Scrolls the view to the anchor of an element
		 */
		public scrollTo( elementAnchor ) {
			var anchor = this.container.one( 'div[name="' + elementAnchor + '"]' );
			if( anchor ) anchor.native.scrollIntoView();
		}
	}

	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Chapter', Chapter );
}