module lernbuch {
	
	export interface ParagraphModel extends ElementModel {
		get( key:'text' ):string;
		get<T>( key:string, fallback?:T ):T;
	}

	/**
	 * An element that renders a paragraph
	 */
	export class Paragraph extends Element {
		
		public model:ParagraphModel;
		
		constructor( model:ParagraphModel ) {
			super( model );
		}
		
		
		/**
		 * Paragraph specific rendering
		 */
		public render() {
			this.renderModel( 'lb.paragraph-element' );
		}
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Paragraph', Paragraph );
}