module lernbuch {

	/**
	 * Search Class.
	 */
	export class Search {

		public book:BookModel;
		private patterns:{ [index:string]: SearchPattern };

		/**
		 * Instantiates a Search
		 */
		constructor( book:BookModel ) {
			this.book = book;
			this.patterns = {};
			this.setDefaultPatterns();
		}
		
		/**
		 * Set the default Patterns
		 */	
		private setDefaultPatterns() {
			this.setPattern( 'Title', new AttributeSearch( [ "title" ] ) );
			this.setPattern( 'Paragraph', new AttributeSearch( [ "text" ] ) );
			this.setPattern( 'Image', new AttributeSearch( [ "caption" ] ) );
			this.setPattern( 'IFrame', new AttributeSearch( [ "caption" ] ) );
			this.setPattern( 'Quote', new AttributeSearch( [ "text", "caption" ] ) );
			this.setPattern( 'Gallery', new ArrayModelSearch( "images", [ "caption" ] ) );
			this.setPattern( 'Listing', new ArraySearch( [ "items" ] ) );
		}
		
		/**
		 * Add a Search-Pattern
		 */		
		public setPattern( elementType:string, searchType:SearchPattern ) {
			this.patterns[ elementType ] = searchType;
		}
		
		/**
		 * Search function gets the term to search for
		 */
		public forString( term:string ):Array<SearchResult> {
			var results:Array<SearchResult> = [];
			// iterate over all the chapters of a book
			this.book.get( 'chapters' ).forEach( ( chapter ) => {
				// iterate over all the elements of a chapter
				chapter.get( 'elements' ).forEach( ( element ) => {
					var modelName = element.get( 'modelName' );
					// add the pattern of the elements modelName
					var pattern = this.patterns[ modelName ];
					if (pattern) {
						// search on the pattern
						var result = pattern.search(term, element);
						// if there is a result, push the result to the results array. 
						if (result) {
							results.push(result);
							result.chapter = chapter;
							result.book = this.book;
						}
					}
				});
			});
			return results;			 
		}
	}
}