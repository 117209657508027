///<reference path="../../view/View.ts"/>
///<reference path="ChapterNavigationItem.ts"/>

module lernbuch {

    /**
     * Interface for all listener function that listen on the top signal
     */
    export interface SelectChapterListener {
        ( chapter:ChapterModel, ...args:any[] );
    }
    
	/**
	 * Renders a list chapters used for navigation between chapters
	 */
	export class ChapterNavigation extends View {

		private lernbuch:LernBuch;
       
        // event when the top visible element has changed
        public select:ln.Signal<SelectChapterListener> = new ln.Signal<SelectChapterListener>();

		constructor( lernbuch:LernBuch, container:ln.Node ) {
			
			super(container);
			this.lernbuch = lernbuch;
			
			this.select.add( this.onSelected, this );	

		}

		/**
		 * Render the template for the chapters
		 */
		public render() {
			// render chapter template
			this.renderTemplate( 'lb.chapter-navigation' );
			// listener for toggeling the navigation dialog
			this.container.one( '.navigation__icon' ).click.add( this.toggleNavigation, this );
		}

		/**
		 * Render all chapter items 
		 */
		public renderChapters( chapterList:Array<ChapterModel> ) {

			// get the container to put all chapters in
			var chapterListContainer = this.chapterlistContainer();

			// render a list of all chapters
			for( var i = 0; i < chapterList.length; i++ ) {
				
				var item = new ChapterNavigationItem( chapterList[i] );
				item.render();
				item.container.click.add( this.onItemClick, this, chapterList[i] );
				chapterListContainer.append( item.container );
			}
		}

		/**
		 * Get the container for the chapter list
		 */
		private chapterlistContainer():ln.Node {
			return this.container.one( '.navigation__chapters' );
		}		
		
		/**
		 * Callback when one of the navigation items was clicked.
		 */
		private onItemClick( container:ln.Node, event:Event, chapter:ChapterModel ) {
			this.select.dispatch( chapter );
		}
		
		
		/**
		 * Callback for selected 
		 */		
		private onSelected( chapter:ChapterModel )  {
			var book_slug = this.lernbuch.book.get( 'slug' );
			var chapter_slug = chapter.get( 'slug' );
			
			var targetURL = ln.Template.render( 'lb.chapter-url', { book_slug: book_slug , chapter_slug: chapter_slug } ); 
			window.location.href = targetURL;			
		}
		
		
		
		/**
		 * Toggles the visibility of navigation dialog
		 */
		private toggleNavigation():void {
			this.container.one( '.navigation__dialog' ).toggleClass( 'hidden' );	
		}
	}
}