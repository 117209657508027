module lernbuch {
	
	export interface QuoteModel extends ElementModel {
		get( key:'text' ):string;
		get<T>( key:string, fallback?:T ):T;
	}

	/**
	 * An element that renders a quote
	 */
	export class Quote extends Element {
		
		public model:QuoteModel;
		
		constructor( model:QuoteModel ) {
			super( model );
		}
		
		
		/**
		 * Paragraph specific rendering
		 */
		public render() {
			this.renderModel( 'lb.quote-element' );
		}
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Quote', Quote );
}