///<reference path="SearchPattern.ts"/>

module lernbuch {

	/**
	 * AtrributeSearch Class.
	 */
	export class AttributeSearch extends SearchPattern {

		public attributes:Array<string>;

		/**
		 * Instantiates an AttributeSearch
		 */
		constructor( attributes ) {
			super();
			this.attributes = attributes;
		}
		
		public search( term:string, element:ElementModel ):SearchResult {
			var result = new SearchResult( element );
			// iterate over all the attributes
			this.attributes.forEach( ( attribute ) => {
				// if there is an attribute, search on the attribute
				if (element.get(attribute)) {
					var text = element.get(attribute).toString();
					var matchFound = text.indexOf(term);
					if (matchFound > -1) {
						result.makeExcerpt( term, text );
					}
				}
			});
			
			// return the result only when there is any excerpt
			return ( result.excerpts.length > 0 ) ? result : null;
		}
		
	}
}