///<reference path="ElementModel.ts"/>

module lernbuch {
    
    export class ChapterModel extends ElementModel {
    
        /**
         * returns an element with the given id of this chapter
         */
        getElement( id ):ElementModel {
            
            // find the element with the given id on the chapter config.
            var found = this.get( 'elements' ).filter( function( element:ElementModel ) {
                return element.get( 'id' ) === id;
            });
            
            return found[0];
        }
        
        get( key:'id' ):number;
        get( key:'modelName' ):string;
        get( key:'title' ):string;
        get( key:'elements' ):Array<ElementModel>;
        get<T>( key:string, fallback?:T ):T;
        
        get<T>( key:string, fallback?:T ):T {
            return super.get( key, fallback );    
        }
    }
}