///<reference path="Element.ts"/>
///<reference path="ElementRenderer.ts"/>

module lernbuch {
	
	export interface ListModel extends ElementModel {
		
		get( key:'items' ):Array<string>;
		get( key:'type' ):string;
		get<T>( key:string, fallback?:T ):T;
	}
	
	/**
	 * An element that renders a list
	 */
	export class List extends Element {
        
		public model:ListModel;
		
        constructor( model:ListModel ) {
            super( model );
        }
		
		/**
		 * List specific rendering
		 */
		public render() {
			this.renderModel( 'lb.list-element' );
		}
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Listing', List );
}