module lernbuch {

	/**
	 * This class generates a slug based on the id and model name
	 */
	export class ElementModel extends Model {
		
		get( key:'id' ):number;
		get( key:'modelName' ):string;
		get( key:'template' ):string;
		get( key:'style' ):string;
		get<T>( key:string, fallback?:T ):T;
		
		get<T>( key:string, fallback?:T ):T {
			return super.get( key, fallback );			
		}
		
		slug():string {
			var prefix = this.get( 'modelName' ).substring( 0, 2 );
			return prefix + this.get( 'id' );
		}
		
		json() {
			var obj:any = super.json();
			if( !obj.slug ) obj.slug = this.slug();
			
			return obj;
		}
	}
}