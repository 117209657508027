module lernbuch {

	 /**
     * Interface for all listener function that listen on the top signal
     */
    export interface SearchResultListener {
        ( term:String, results:Array<SearchResult>, ...args:any[] );
    }
	
	
	
	/**
	 * SearchInput Class.
	 */
	export class SearchInput extends View {
		
		public search:Search;
		public minChars = 3;
		public results:ln.Signal<SearchResultListener> = new ln.Signal<SearchResultListener>();
		
		constructor( container:ln.Node, book ) {
			
			super(container);
			
			this.search =  new lernbuch.Search( book );

		}
		
		public render() {
			this.container.setHTML( ln.Template.render( 'lb.search-input' ) );
			var input = this.container.one( 'input' );
			input.keyup.add( ()=> {
				if (input.value().length >= this.minChars) {
					var searchResults = this.search.forString( input.value());
				}
				this.results.dispatch(input.value(), searchResults);
			});
			
			input.click.add( ()=> {
				if ( input.value() == "Suchen" ) {
					input.value( "" );
				}
			});
			
			input.blur.add( ()=> {
				if ( input.value() == "" ) {
					input.value( "Suchen" );
				}
			});
		}
	}
}