///<reference path="../view/View.ts"/>

module lernbuch {

	/**
	 * A base class for elements that can be rendered in a ChapterRenderer
	 */
	export class Element extends View {

		public model:ElementModel;
		
		public isVisible:ln.Signal<ln.EmptyCallback> = new ln.Signal<ln.EmptyCallback>();
		public isHidden:ln.Signal<ln.EmptyCallback> = new ln.Signal<ln.EmptyCallback>();

		/**
		 * Sets the configuration for an element
		 */
		constructor( model:ElementModel ) {
			super();
			this.model = model;
		}

		/**
		 * Renders the element into its container.
		 */
		public render() {
			this.renderModel( 'element' );
			
		}
		
		/**
		 * Renders the model into the template with the given name.
		 * If the model has a template property set, this template name is used for rendering
		 * @param templateName The name of the template to render.
		 */
		public renderModel( templateName:string ) {
			this.renderTemplate( this.model.get( 'template', templateName ), this.model.json() );
			this.container.setAttribute( 'name', this.model.slug() );
			this.container.setAttribute( 'id', this.model.slug() );
			// inject css style if there is any.
			if( this.model.get( 'style' ) ) this.container.addClass( this.model.get( 'style' ) );
		}
	}
}