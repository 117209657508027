///<reference path="Element.ts"/>
///<reference path="ElementRenderer.ts"/>

module lernbuch {
	
	export interface IFrameModel extends ElementModel {
		
		get( key:'source' ):string;
		get( key:'height' ):number;
		get( key:'caption' ):string;
		get<T>( key:string, fallback?:T ):T;
	}
	
	/**
	 * An element that renders an iframe for animations
	 */
	export class IFrame extends Element {
		
		public model:IFrameModel;
		
        constructor( model:IFrameModel ) {
            super( model );
        }
        
		/**
		 * Animation specific rendering
		 */
		public render() {
			this.renderModel( 'lb.iframe-element' );
        }
       
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'IFrame', IFrame );
}