///<reference path="Element.ts"/>
///<reference path="ElementRenderer.ts"/>

module lernbuch {
	
	export interface GalleryModel extends ElementModel {
		
		get( key:'images' ):Array<GalleryImageModel>;
		get<T>( key:string, fallback?:T ):T;
	}
	
	export interface GalleryImageModel {
		src:string;
		caption:string;
	}
	
	/**
	 * An element that renders a gallery
	 */
	export class Gallery extends Element {
        
		public model:GalleryModel;
		
        private currentImage:number = 0;
        
        constructor( model:GalleryModel ) {
            super( model );
        }
		
		/**
		 * gallery specific rendering
		 */
		public render() {
			
			this.renderModel( 'lb.gallery-element' );
			
            // register click listener on the buttons
            this.container.one( '.gallery__button--next' ).click.add( this.goToNextImage, this );
            this.container.one( '.gallery__button--prev' ).click.add( this.goToPrevImage, this );
			
            this.container.mouseover.add( this.showNavigation, this );
            this.container.mouseout.add( this.hideNavigation, this );
			
            // render elements
            this.renderImageNodes();
            // show the first image
            this.showImage();
		}
        
        /**
         * render the image nodes
         */
        private renderImageNodes() {
			
			var images = this.model.get( 'images', [] );
			var imageContainer = this.container.one( '.gallery__image-container' );
			var paginContainer = this.container.one( '.pagination' );
			
            // loop over the images. append them to the image container
            for( var i = 0; i < images.length; i++ ) {
				
                // image-node erstellen
                var node = ln.Node.fromHTML( ln.Template.render( 'lb.gallery-image-element', images[i]) );
                node.addClass( "gallery__image", "hidden" );

                // Todo: throw an error if the container was not found
                imageContainer.append( node );
                
                // pagination 
                var paginationElement = ln.Node.fromHTML( ln.Template.render( 'lb.gallery-pagination-element', images[i]) );
				
                // click hander registrieren
                paginationElement.click.add( this.jumpToImage, this, i.toString() );
                paginContainer.append( paginationElement );
            }
        }
        
        /**
         * function to go to the next image within the gallery
         */
        private goToNextImage() {
            ++this.currentImage;
            this.showImage();
        }
        
        /**
         * function to go to the previous image within the gallery
         */
        private goToPrevImage() {
            --this.currentImage;
            this.showImage();
        }
        
        private jumpToImage ( clickedElement, event:Event, imageId:number ) {
            // get the image-id of the clicked element 
            this.currentImage = imageId;
            this.showImage();
        }
        
        /**
         * go to the current image
         */
        private showImage() {
			
            var nodes = this.container.one(".gallery__image-container").all(".gallery__image");
			
            // rotation 
            if (this.currentImage > (nodes.size() - 1) ) {
                this.currentImage = 0;
            } 
            if (this.currentImage < 0 ) {
                this.currentImage = nodes.size() - 1;
            } 
            
            nodes.each( ( node, index ) => {
                // toggle class on the images. hide all the images except the active one    
                node.toggleClass( 'hidden', index != this.currentImage );
                // animataion: fade-in the currentImage
                node.toggleClass( 'fade-in', index == this.currentImage );
            });
            
            // toggle class on the pagination. "active" on the current element
            var paginationElements = this.container.one(".pagination").all(".pagination__element");
            paginationElements.each( ( paginationElement, index ) => {
                paginationElement.toggleClass( 'pagination__element--active', index == this.currentImage );
            });
        }
        
        private showNavigation() {
			this.container.all( '.gallery__button' ).removeClass( 'hidden' );
        }
        
        private hideNavigation() {
			this.container.all( '.gallery__button' ).addClass( 'hidden' );
        }
        
	}
	
	// register class for auto instantiation
	ElementRenderer.elementRegister.register( 'Gallery', Gallery );
}