module lernbuch {

	/**
	 * Dialogbox for sharing content of the lb
	 */
	export class ShareDialog extends View {

		private lernbuch:LernBuch;
		private scrollMonitor:ScrollMonitor;
		
		private fbsharelink = "https://www.facebook.com/sharer/sharer.php?u=";
		private twsharelink = "https://twitter.com/home?status=";
		private mailsharelink = "mailto:?body=";
		
		
		constructor( lernbuch:LernBuch, container:ln.Node ) {
			super(container);
			
			this.lernbuch = lernbuch;
			this.scrollMonitor = lernbuch.scrollMonitor;
		}
		
		/**
		 * Renders the template for the sharedialog
		 */
		public render() {
			this.renderTemplate( 'lb.sharedialog' );
			
			this.container.one( '.share__icon' ).click.add( this.toggleShare, this );
		}
		
		/**
		 * Toggles the visibility of the share dialog
		 */
		private toggleShare():void {
			this.container.one( '.share__dialog' ).toggleClass( 'hidden' );
			this.updateLinks();
	
			if( this.scrollMonitor.change.has( this.updateLinks, this ) ){
				this.scrollMonitor.change.remove( this.updateLinks, this );
			} else {
				this.scrollMonitor.change.add( this.updateLinks, this );
			}
			
			// not yet cross browser ready.
			//input.native.setSelectionRange(0, input.value().length);
		}
		
		/**
		 * Update the share links
		 */
		private updateLinks():void {
			var input = this.container.one( '.share input' );
			if( this.scrollMonitor.visibleElements.length == 0 ){
				input.value( window.location.toString() );
				this.setShareLinks( window.location.toString() );
			} else {
				input.value( this.setHash() );
				this.setShareLinks( this.setHash() );
			}
		}
		
		/**
		 * Set the share links
		 */
		private setShareLinks( url: string ) {
			var fb = this.container.one( '.share__fb' );
			fb.setAttribute( 'href', this.fbsharelink + encodeURIComponent( url ) );
			var tw = this.container.one( '.share__tw' );
			tw.setAttribute( 'href', this.twsharelink + encodeURIComponent( url ) );
			var mail = this.container.one( '.share__mail' );
			mail.setAttribute( 'href', this.mailsharelink + this.setMailBody( url ) );
		}
		
		/**
		 * Set the body of the mail
		 */
		private setMailBody( url: string ) {
			var body = this.lernbuch.book.get( 'title' ) + ' - ' + this.lernbuch.view.currentChapter.model.get( 'title' );
			return encodeURIComponent( body ) + '%0D%0A%0D%0A'
				+ encodeURIComponent( url );
		}
	
		/**
		 * Set the hash of the url to the first visible element
		 */
		private setHash(): string {
			var id = this.scrollMonitor.visibleElements[0].model.slug();
			var hash = window.location.hash;
			var newHash = '#' + id;
			return ( hash == '' ) ? window.location.toString() + newHash :
				window.location.href.replace( hash, newHash );
		}
	}
}