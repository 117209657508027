///<reference path="navigation/chapter/ChapterNavigation.ts"/>
///<reference path="navigation/outline/OutlineNavigation.ts"/>
///<reference path="navigation/share/ShareDialog.ts"/>
///<reference path="navigation/paging/PagingNavigation.ts"/>
///<reference path="view/LernBuchView.ts"/>
///<reference path="ScrollMonitor.ts"/>
///<reference path="models/BookModel.ts"/>
///<reference path="lib/ln-common.d.ts"/>

module lernbuch {
	
	/**
	 * This class setups all required elements for a default lernbuch application.
	 * It instantiates the LernBuchView that renders the chapters and elements.
	 * It instantiates a backend that gets all the configurations for the chapters ans elements 
	 */
	export class LernBuch {
		
		public book:BookModel;
		public view:LernBuchView;
		public shareDialog:ShareDialog;
		public navigation:ChapterNavigation;
		public scrollMonitor:ScrollMonitor;
		public outline:OutlineNavigation;
		public paging:PagingNavigation;

		constructor( contentContainer:ln.Node ) {
			this.view = new LernBuchView( contentContainer );
			this.scrollMonitor = new ScrollMonitor( this );
			this.navigation = new ChapterNavigation( this, this.view.container.one( '.navigation' ) );
			this.paging = new PagingNavigation( this, this.view.container.one( '.paging-navigation' ) );
			this.shareDialog = new ShareDialog( this, this.view.container.one( '.share' ) );
			this.outline = new OutlineNavigation( this, this.view.container.one( '.outline-navigation' ) );
	
		
		}
		
		/**
		 * Setups the backend to an offline backend and stores the data into the backend
		 */
		public setData( book:BookModel ) {
			
			this.book = book;
			
			// we got the data, we can render.
			this.shareDialog.render();
			
			this.navigation.render();
			this.navigation.renderChapters( this.book.get( 'chapters' ) );
			
			this.paging.render();
			
			this.setTitle( this.book.get( 'title' ) );
			
			this.outline.renderBook( this.book );
		}
		
		/**
		 * Renders the book data into the menu bar
		 */
		public setTitle( title:string ) {
			this.view.container.one( '.menubar__title' ).setHTML( title );
		}
		
		
		public showChapter( chapterSlug ) {
			this.view.renderChapter( this.book.getChapter( chapterSlug ) );
		}
	}
}