///<reference path="../../view/View.ts"/>

module lernbuch {

	/**
	 * Renders a chapter navigation item
	 */
	export class ChapterNavigationItem extends View {

		private model:ChapterModel;

		constructor( model:ChapterModel ) {
			super();
			this.model = model;
		}

		/**
		 * Renders the template for the chapter item
		 */
		public render() {
			this.container.setHTML( ln.Template.render( 'lb.chapter-navigation-item', this.model.json() ) );
		}

	}
}