module lernbuch {

	/**
	 * SearchOutput Class.
	 */
	export class SearchOutput {

		public template:string;
		public container:ln.Node;
		
		/**
		 * Instantiates a SearchOutput
		 */
		constructor( container:ln.Node, template:string = "lb.search-output-result" ) {
			this.template = template;
			this.container = container;
		}
		
		public render( term:string, searchResults:Array<SearchResult> ){
			var outputList = this.container.one(".search-output__list");
			outputList.setHTML("");
			for( var i = 0; i < searchResults.length; i++ ) {
				var node = ln.Node.fromHTML( ln.Template.render( this.template, searchResults[i] ) );
				outputList.append( node );
			}
						
			// not needed at the moment: for the "detailed search" link.
			//this.container.one(".search-output__search-term").setHTML( term );
		}
	}
}