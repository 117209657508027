
module lernbuch {
	
	/**
	 * Common interface for any Models.
	 */
	export interface IModel {
		get<T>( key:string, fallback?:T ):T;
		set( key:string, value:any );
		json():Object;
	}
	
	/**
	 * Function interface for listeners on the 'update' Signal. 
	 */
	export interface ModelUpdateListener {
		( newValue:any, oldValue:any ):void;
	}
	
	/**
	 * A common model class that has getters and setters 
	 */
	export class Model {
		
		protected obj:Object;
		public update:ln.Signal<ModelUpdateListener>;
		
		
		constructor( obj:Object = {} ) {
			this.obj = obj;
			this.update = new ln.Signal<ModelUpdateListener>();
		}
		
		/**
		 * Returns the value of the model with the given key.
		 * If the key does not exists the given optional fallback value is returned.
		 * @param key The key on the model to lookup
		 * @param fallback The fallback value when the key does not exists.
		 */
		get<T>( key:string, fallback:T = undefined ):T {
			var value = this.obj[ key ];
			return ( value ) ? value : fallback;
		}

	
		/**
		 * Sets the given value on the given key in the model.
		 * @param key The key on the model to adjust its value
		 * @param value The new value to set of the model
		 */
		set( key:string, value:any ) {
			
			var old = this.obj[ key ];
			if( old != value ) {
				this.obj[ key ] = value;
				this.update.dispatch( value, old );
			}
		}
		
		/**
		 * Returns the model as a json object. 
		 */
		json():Object {
			return this.obj;
		}
	}
}