module lernbuch {

	/**
	 * SearchPattern Class.
	 */
	export class SearchPattern {


		/**
		 * Instantiates a SearchPattern
		 */
		constructor(  ) {
			
		}
		
		public search( term:string, element:ElementModel ):SearchResult {
			var result:SearchResult = new lernbuch.SearchResult( element );
			return null;
		}
	}
}