///<reference path="SearchPattern.ts"/>

module lernbuch {

	/**
	 * ArraySearch Class.
	 */
	export class ArrayModelSearch extends SearchPattern {

		public attributes:Array<string>;
		public elementType:string;

		/**
		 * Instantiates an ArraySearch
		 */
		constructor( elementType, attributes ) {
			super();
			this.elementType = elementType;
			this.attributes = attributes;
		}
		
		
		public search( term:string, element:ElementModel ):SearchResult {
			// get the sub-elements
			var elements = element.get(this.elementType);
			// create a new SearchResult
			var result = new SearchResult( element );
			// iterate over the sub-elements
			for (var key in elements) {
				// iterate over the attributes
				this.attributes.forEach( ( attribute ) => {
					// if there is an element with the attribute, search on the attribute
					if (elements[key][attribute]) {
						var text = elements[key][attribute].toString();
						var matchFound = text.indexOf(term);
						if (matchFound > -1) {
							result.makeExcerpt( term, text );
						}
					}
				});
			}
			// return the result only when there is any excerpt
			return ( result.excerpts.length > 0 ) ? result : null;
		}
	}
}