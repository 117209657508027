module lernbuch {

	/**
	 * A class that can render a list of elements into its view container.
	 */
	export class ElementRenderer extends View {

		/**
		 * storage of all rendered elements
		 */
		public elements:Array<Element>;

		/**
		 * The class register to store all string - constructor mappings
		 */
		public static elementRegister:ln.ClassRegister = new ln.ClassRegister();


		constructor() {
			super();
			this.elements = [];
		}

		/**
		 * Renders a list of models/configs into the container
		 */
		public render( elements:Array<ElementModel> ):void {

			for( var i = 0; i < elements.length; i++ ) {

				// create modelName specific instance
				var element = ElementRenderer.elementRegister.instantiate<Element>( elements[i].get( 'modelName' ), elements[i] );
				element.render();
				element.container.addClass( 'element' );

				// append to view container
				this.container.append( element.container );

				// store reference to element
				this.elements.push( element );
			}
		}
	}
}